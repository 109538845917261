import "./bootstrap-config";
import "./font-awesome-config";
import "./theme";
import "../styles/main.scss";

// Uncomment to add Animate on Scroll library
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

// Uncomment to add Swiper carousel library
import "./swiper-config";

// Uncomment to add Masonry Layout library
// import Masonry from "masonry-layout";

// Uncomment to add Fancybox light box library
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]", {});